












































import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class Add extends Vue {
  private info = {
    isMessage: '0',
    messageTitle: '',
    messageContent: '',
    startTime: '',
    endTime: ''
  }

  private rules= {
    messageTitle: [
      { required: true, message: '请输入公告标题', trigger: 'change' }
    ],
    messageContent: [
      { required: true, message: '请输入公告内容', trigger: 'change' }
    ],
    startTime: [
      { required: true, message: '请输入公告内容', trigger: 'change' }
    ]
  }

  private date: string[]= []

  private submitShow = false

  created () {
    this.$route.params.id && this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.message.selectMessageByMessageId, {
      messageId: this.$route.params.id
    }).then((res) => {
      this.info = res
      this.date = [res.startTime, res.endTime]
    })
  }

  dateChange (date: string[]) {
    if (date) {
      this.info.startTime = date[0]
      this.info.endTime = date[1]
    } else {
      this.info.startTime = ''
      this.info.endTime = ''
    }
  }

  // 效验表单
  verification (status: string) {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.onSubmit(status)
      }
    })
  }

  onSubmit (status: string) {
    this.submitShow = true
    const url = this.$route.params.id ? this.$apis.message.updateMessage : this.$apis.message.insertMessage
    if (this.date && this.date.length > 0) {
      this.info.startTime = this.date[0]
      this.info.endTime = this.date[1]
    }
    this.$axios.post(url, {
      ...this.info,
      status: status
    }).then(() => {
      this.$message.success('保存成功')
      this.$router.back()
    }).finally(() => {
      this.submitShow = false
    })
  }
}
